import { Component } from '@angular/core';

@Component({
  selector: 'app-specialty-pizzas',
  templateUrl: './specialty_pizzas.component.html',
  styleUrls: ['./specialty_pizzas.component.css']
})
export class SpecialtyPizzaComponent {

}
