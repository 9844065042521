import { Component } from '@angular/core';

@Component({
  selector: 'app-byo-pizza',
  templateUrl: './build_your_own.component.html',
  styleUrls: ['./build_your_own.component.css']
})
export class BuildYourOwnComponent {

}
